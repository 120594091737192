.detail-modal-container .title-row .rental-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 12px 16px 12px 16px;
  appearance: none;
  vertical-align: baseline;
}

.detail-modal-container .detail-view .map-view .found-at {
  width: auto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: 0.6px;
  text-align: left;
}

.detail-modal-container .detail-view .map-view .detected-at {
  font-size: 12px;
  margin-top: 0px;
}

.detail-modal-container .main-view .mamorio-name {
  height: 18px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 18px;
  text-align: center;
}

.detail-modal-container .main-view .mamorio-battery {
  margin-top: 15px;
}

.detail-modal-container .main-view .mamorio-battery-title {
  margin-right: 12px;
}

.detail-modal-container .main-view .status-text {
  height: 15px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.93;
  letter-spacing: 0.6px;
  margin-top: 15px;
  text-align: center;
}

.detail-modal-container .uncontrolled-dropdown {
  list-style-type: none;
  display: inline-block;
}

.detail-modal-container .dropdown-toggle::after {
  display: none;
}

.detail-modal-container .dropdown-menu {
  top: 80%;
  right: 0;
  left: auto;
  padding: 0;
}

.detail-modal-container .dropdown-item,
.detail-modal-container .dropdown-item:hover {
  color: #c9250a;
}

.confirm-modal-background {
    background-color: #00000033;
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    z-index: 8000;
}

.confirm-modal-background .confirm-modal-container {
    background-color: white;
    width: 45%;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
}

.confirm-modal-background .confirm-modal-container .modal-attention-icon {
    margin: 25px 0px 26.5px 0px;
}
.confirm-modal-background .confirm-modal-container .modal-attention-icon > img {
    width: 39px;
    height: 39px;
}

.confirm-modal-background .confirm-modal-container .modal-message {
    margin: 0px 41.5px 26.5px 42px
}

.confirm-modal-background .confirm-modal-container .delete-group-button {
    margin: 0px 0px 26px 0px;
    text-align: center;
}

.confirm-modal-background .confirm-modal-container button {
    letter-spacing: 1px;
    width: 200px;
    height: 40px;
}

.confirm-modal-background .confirm-modal-container .cancel-delete-button {
    margin: 0px 0px 25px 0px;
    font-family: HiraKakuProN-W3;
    letter-spacing: 1px;
    text-align: center;
    color: #4ca7db;
}

@media (max-width: 768px) { 
    .confirm-modal-background .confirm-modal-container {
        width: 92%;
    }
}
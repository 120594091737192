.mamorio-nav {
    background-color: #000;
    color: #FFF;
    height: 40px;
    text-align: center;
    z-index: 2000;
}

.mamorio-nav .mamorio-home {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    line-height: 40px;
}

.mamorio-nav .logo {
    height: 16px;
    vertical-align: text-top;
}

.mamorio-nav-right {
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
}

.mamorio-nav-right .news-icon, .faq-icon {
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  color: white;
  display: flex;
  text-decoration: none;
  align-items: baseline;
}

.mamorio-nav-right .news-icon img, .faq-icon img {
  vertical-align: middle;
  margin: 0px 0px 0px 10px;
  height: 27px;
  width: 27px;
}

.mamorio-nav-right .news-icon .unreadNewsCount {
  color: white;
  background-color: red;
  font-size: 12px;
  padding: 4px;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  margin-left: -20px;
}

.mamorio-nav-right .current-user {
    display: inline-block;
    width: 100%;
    padding: 5px 5px 5px 0px;
    margin-right: 10px;
    cursor: pointer;
    color: white;
}

.mamorio-nav-right .current-user:hover {
    background-color: #4ca7db;
    text-decoration: none;
}

.mamorio-nav-right .current-user img {
    vertical-align: middle;
    margin: 0px 10px 0px 10px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
}

.mamorio-nav-right .logout {
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
    border: 1px solid #FFF;
    border-radius: 5px;
    height: 1.8em;
    vertical-align: middle;
    line-height: 1.9em;
    padding: 0px 10px 0px 10px;
}

.mamorio-nav-right .logout:hover {
   background-color: #4ca7db;
}

.mamorio-nav-right .logout img {
    width: auto;
    height: 40px;
    display: none;
    padding: 5px 10px 5px 10px;
}

.mamorio-nav-left {
    top: 0;
    left: 0;
    position: absolute;
}

.mamorio-nav-left .burger-icon {
    width: auto;
    height: 40px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
}

.mamorio-nav-left .burger-icon:hover {
    background-color: #4ca7db;
}

@media only screen and (max-width: 768px) {
    .mamorio-nav-right .logout {
        display: none;
    }

    .mamorio-nav-right .current-user {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 1024px) {
    .mamorio-nav-right .current-user span {
        display: none;
    }

    .mamorio-nav-right .current-user {
        padding-right: 0px;
    }
}

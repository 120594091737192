.form-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: .5rem auto;
  background-color: #E8E8E8;
  background-image: url('../../icons/icon_circle@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

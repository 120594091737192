.overview-list .table .list {
    border-top: 1px solid #dcdcdc;
}

.overview-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.overview-title .group-name img {
    height: 22px;
    margin-right: 10px;
}

.overview-title .group-name {
    color: #5a5a5a;
    font-size: 24px;
    font-weight: bold;
}

.overview-title .btn:first-child {
    margin-left: 0;
}

.overview-title .btn {
    margin-left: 10px;
}

.edit-user-modal {
  justify-content: center;
}

input {
  width: 100%;
  max-width: 400px;
  height: 44px;
  border: 1px solid #dcdcdc;;
  border-radius: 4px;
  padding: 0 0 0 2%;
  color: #5a5a5a;
}

.user-settings-column {
  padding: 15px 30px 15px;
  color: #5a5a5a;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-settings-column label {
  margin: 0px 20px 0px 0px;
  width: 155px;
}

.user-settings-button-column {
  margin: 1.5rem auto;
  text-align: center;
}

.user-settings-button-column .disabled {
  background-color: #e8e8e8 !important;
  color: #9d9d9d !important;
}
.news-page-title {
  color: #5a5a5a;
  font-size: 36px;
  font-weight: bold;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px;
}

.news {
  border-bottom: 1px solid #dcdcdc;
  padding: 15px 5px 15px 5px;
}

.news .title {
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 24px;
  color: #5a5a5a;
}

.news .news-content {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.news-body {
  position: relative;
  z-index: 200;
  background-color: white;
  /* box-shadow: 0px 1.5px 1px 0px rgb(0 0 0 / 15%); */
  padding: 30px;
  color: #575757;
}

.posted_at {
  text-align: right;
  color: #757575;
}
.mamorio-error-modal {
  max-width: 800px;
  width: 80%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.mamorio-error-modal a {
  color: #4ca7db;
  text-decoration: none;
}

.mamorio-error-modal .mamorio-error-modal-item {
  margin: 30px;
  font-size: 1em;
  line-height: 1.6em;
  letter-spacing: 0.8px;
  overflow: hidden;
}

.mamorio-error-modal .mamorio-error-modal-image {
  width: 41px;
  height: 41px;
  margin: 0 auto 20px;
}

.mamorio-error-modal .btn {
  width: 140px;
  height: 40px;
}

.mamorio-error-modal-message {
  margin-bottom: 40px;
}

#overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

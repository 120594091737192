.pagination-wrap {
    margin: 10px auto;
    width: 235px;
    display: flex;
    justify-content: center;
}

.pagination-wrap .pagination .page-item .page-link {
    color: #4ca7db;
}

.pagination-wrap .pagination .page-item.active .page-link {
    color: white;
    border-color: #4ca7db;
    background-color: #4ca7db;
}


.search-bar .filter-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    color: #5a5a5a;
    vertical-align: baseline;
    margin-bottom: 5px;
}

.search-bar .dropdown-toggle {
    background-color: #FFF;
    color: #5a5a5a;
}

.search-bar .dropdown-item {
    cursor: pointer;
}

.search-bar .btn:hover,
.search-bar .dropdown-item:hover {
    background-color: #4ca7db;
    color: #FFF;
}

.search-bar .filter-menu .filter-title {
    width: 150px;
    margin-right: 16px;
}

.search-bar .filter-menu .top {
  display: flex;
}

.search-bar .filter-menu .filter-input {
    margin-right: 32px;
    display: flex;
    align-items: center;
}

.search-bar .filter-menu .filter-input .filter-title {
    width: 115px;
    font-size: 14px;
}

.search-bar .filter-menu .form-control {
    width: 200px;
}

.search-bar .filter-menu .search-icon {
    height: 24px;
    margin-right: 10px;
    vertical-align: text-bottom;
}

.search-bar .filter-menu .filter-status {
    display: flex;
    align-items: center;
    margin-right: 32px;
}

.search-bar .filter-menu .filter-status .filter-title {
    width: 70px;
    font-size: 14px;
}

.search-bar .clear-fix {
    clear: both;
}

@media (max-width: 768px) {
   /* .search-bar .filter-menu {
        padding-bottom: 0px;
    }
    .search-bar .filter-menu .form-control {
        width: 200px;
    }
    .search-bar {
        min-width: auto;
    }
    .search-bar .btn {
        width: auto;
    }*/
}

.search-bar .filter-toggle {
  display: flex;
}

.search-bar .filter-toggle .filter-title {
  font-size: 14px;
  height: 21px;
}

.search-bar .filter-toggle svg {
    color: white;
}


.date-conatiner {
  padding-left: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.date-conatiner .date {
  margin-left: 10px;
  width: 180px;
}

.date-conatiner button {
  width: 240px;
  height: 44px;
  margin-left: 20px;
  font-size: 16px;
}

.base-modal {
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    width: 100%; height: 100%;
    background-color: white;
    z-index: 7000;
}

.base-modal .base-modal-header {
    font-size: 24px;
    color: #5a5a5a;
    height: 82.5px;
    border-bottom: solid 0.5px #cccccc;
}

.base-modal .base-modal-header .header-center,
.base-modal .base-modal-header .header-left {
    position: absolute;
}

.base-modal .base-modal-header .header-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.base-modal .base-modal-header .header-left {
    right: 15%;
}

.base-modal .base-modal-header .close-button {
    height: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
}

.base-modal .base-modal-header .close-button:hover {
    background-color: #eee;
}

.base-modal .base-modal-header .close-button .base-modal-button {
    height: 17px;
    font-size: 12px;
    text-align: center;
    color: #5a5a5a;
}

.base-modal .base-modal-header .close-button-image {
    width: 29px;
    height: 29px;
}

.base-modal .base-modal-container {
    width: 80%;
    margin: 70px auto;
    max-width: 768px;
    text-align: center;
}

.base-modal-container .modal-form > * {
    margin: 1.5rem auto;
}

.base-modal-container .modal-form > .loading-bg {
    margin: 0;
}

.modal-form > div {
    display: flex;
    flex-wrap: wrap;
}

.modal-form > button {
    display: block;
}

.modal-form > div > input {
    border: solid 1px #dcdcdc;
    border-radius: 4px;
    text-indent: 22px;
    width: 420px;
}

.modal-form select {
  border: solid 1px #dcdcdc;
  border-radius: 4px;
  text-indent: 22px;
  width: 400px;
  height: 44px;
  padding-left: 13px;
}

.modal-form input::placeholder {
    color: #9a9a9a;
}

.modal-form label {
    color: #5a5a5a;
    flex-basis: 30%;
    line-height: 2.5rem;
    text-align: left;
}

.modal-form div + label {
    margin-top: 20px;
}

.modal-form label.permission-switch {
  display: flex;
  justify-content: center;
  line-height: initial;
  padding-top: 15px;
  padding-bottom: 15px;
}

.modal-form label.permission-switch span {
  width: 240px;
}

.modal-form input + label {
    cursor: pointer;
    color: #4ca7db;
    height: 32px;
}

.modal-form button {
    height: 40px;
    width: 200px;
}

.modal-form .form-check  * {
    margin: 0 .25rem;
    position: initial;
}

.modal-form .btn-primary {
    margin-top: 64px;
}

.modal-form .cancel-button {
    margin-bottom: 100px;
}

/* layout for mobile */

@media (max-width: 768px) {
    .base-modal .base-modal-header {
        justify-content: normal;
        padding-left: 14px;
        height: 50px;
        font-size: 18px;
    }

    .base-modal .base-modal-header .header-center {
        left: auto;
        top: auto;
        transform: none;
        padding-right: 50px;
    }

    .base-modal .base-modal-header .header-left  {
        right: 2%;
    }

    .base-modal .base-modal-header .close-button .base-modal-button {
        display: none;
    }

    .base-modal .base-modal-header .close-button > img {
        width: 18.9px;
        height: 18.7px;
    }

    .base-modal .base-modal-container {
        width: 90%;
        margin: 20px auto;
        max-width: 768px;
    }
}

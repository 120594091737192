.group-rental-modal .top-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #5a5a5a;
}

.group-rental-modal .middle-section {
    display: flex;
    justify-content: center;
}

.group-rental-modal .middle-section .step-section {
    margin-left: 50px;
    text-align: left;
}

.group-rental-modal .middle-section .step-section .step:nth-child(2) {
    margin-top: 50px;
}

.group-rental-modal .middle-section .step-section .step-title {
    color: #4ca7db;
    font-size: 12px;
    margin-bottom: 5px;
}

.group-rental-modal .middle-section .step-section .step-desc {
    color: #5a5a5a;
    font-size: .8rem;
    line-height: 2rem;
    margin-bottom: 20px;
}

.group-rental-modal .middle-section .step-section .btn {
    min-width: 260px;
}

.group-rental-modal .middle-section .step-section .button-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.group-rental-modal .middle-section .step-section .button-group > div > * {
    display: inline-block;
}

.group-rental-modal .middle-section .step-section img {
    margin-top: 10px;
    width: 260px;
}

.group-rental-modal .middle-section .qr-section .qr-title {
    width: 100px;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    border-radius: 12.5px;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid #000;
}

.group-rental-modal .middle-section .qr-section .qr-code-content {
    display: inline-block;
    width: 100%;
    height: 120px;
}

.group-rental-modal .middle-section .qr-section .loader {
    height: 48px;
    margin: 36px 36px 36px 36px;
}

.group-rental-modal .middle-section .qr-section .qr-desc {
    font-size: 12px;
    display: inline-block;
}

.group-rental-modal .qr-section > svg {
    display: none;
}

.group-print-qr {
    padding: 100px 0 100px 0;
}

.group-print-qr svg {
    position: absolute;
    width: 100%;
    height: calc(100% - 200px);
    left: 0;
}

.group-print-qr .qr-print {
    width: 480px;
    text-align: center;
    padding: 100px 0px 0px 0px;
    margin: 0 auto;
}

.group-print-qr .qr-code-content .qr-code {
    width: 100%;
    height: auto;
    z-index: 100;
    margin-top: 40px;
    margin-bottom: -40px;
}

/* Safari Optimizations */
.group-print-qr:not(:root:root){ 
    padding: 60px 0 23px 0 !important;
}
.group-print-qr svg:not(:root:root){ 
    height: calc(100% - 83px) !important;
}
.group-print-qr .qr-print:not(:root:root){
    width: 450px !important;
}
.group-print-qr img.qr-code:not(:root:root){
    margin-top: 33px !important;
}
/* Safari Optimizations End */

.group-print-qr div.qr-title {
    width: 300px;
    height: 64px;
    position: absolute;
    left: 0; 
    right: 0; 
    line-height: 64px;
    border-radius: 32px;
    font-size: 32px;
    letter-spacing: 1px;
    border: 2px solid #000;
    margin: 0 auto;
    z-index: 1000;
}

.group-print-qr div.qr-desc {
    font-size: 40px;
}

@media only screen and (max-width: 768px) {
    .group-rental-modal .middle-section {
        flex-wrap: wrap;
    }
    .group-rental-modal .middle-section .qr-section {
        margin: 20px 0px 20px 0px;
    }
    .group-rental-modal .middle-section .step-section {
        margin-left: 0px;
    }
    .group-rental-modal .middle-section .step-section .tut-step1 {
        text-align: center;
    }
    .group-rental-modal .middle-section .step-section .button-group {
        flex-wrap: wrap;
    }
    .group-rental-modal .middle-section .step-section .button-group > div {
        text-align: center;
    }
}

@media print {
    @page {
        size: auto !important;
        margin: 0mm !important;
    }
    body {
        margin: 0mm;
    }
}


.mamorio-settings {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mamorio-settings .table {
    color: #757575;
    margin-bottom: 20px;
}

.mamorio-settings .table .list {
    background-color: white;
    /* box-shadow: 0 2px 2px 0 transparent, 0 0 10px 0 rgba(0, 0, 0, .1); */
    cursor: pointer;
    border-top: none !important;
}

.mamorio-settings .table .header {
    margin-bottom: 5px;
    padding: 0px 15px 0px 15px;
}

.mamorio-settings .table .header th {
    border-top: none;
    padding-bottom: 4px;
}

.mamorio-settings .table .header .title {
    font-size: 12px;
    font-weight: normal;
}

.mamorio-settings .table .header .title img {
    width: 15px;
    height: 15px;
}

.mamorio-settings .table .list tr {
    border-bottom-width: 1px;
}

.mamorio-settings .table .list td {
    border: none;
    vertical-align: middle;
    padding: 12px;
}

.mamorio-settings .mamorio-item {
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    padding: 16px 0px 16px 0px;
}

.mamorio-settings .mamorio-item img {
    width: 40px;
    height: 40px;
    border-radius: 46px;
    margin-right: 10px;
}

.mamorio-settings .mamorio-item .name {
    vertical-align: middle;
    display: inline-block;
    color: #5a5a5a;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: default;
}

.mamorio-settings .mamorio-item {
    margin-left: auto;
}

.mamorio-settings .empty-list {
    text-align: center;
    margin-top: 40px;
}

.mamorio-settings .empty-list img {
    width: 140px;
}

.mamorio-settings .empty-list .title {
    font-size: 20px;
    color: #5a5a5a;
    line-height: 1.2;
    margin-top: 30px;
}

.mamorio-settings .empty-list .description {
    font-size: 18px;
    color: #5a5a5a;
    line-height: 1.67;
    margin-bottom: 40px;
}

.mamorio-settings .message a {
    color: #4ca7db;
}

.mamorio-settings .img-name-item {
    display: flex;
    align-items: center;
}

.mamorio-settings .item-detected-by .item-icon {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    border-radius: 36px;
}

.mamorio-settings .img-name-item svg {
    background-color: lightgray;
    padding: 6px;
}

/* layout for mobile */
@media (max-width: 768px) { 
    .mamorio-settings .mamorio-item .options .btn {
        min-width: 70px;
        width: auto;
    }
}

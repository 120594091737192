body {
  background-color: #F4F4F4;
}

.mamorio-login-form {
  max-width: 370px;
  width: 80%;
  height: auto;
  margin: 200px auto 0;
}

.mamorio-login-form .mamorio-login-logo {
  width: 100%;
  height: auto;
  margin: 0 auto 40px;
}

.mamorio-login-form input {
  width: 100%;
  height: 44px;
  margin: 0 auto 24px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-size: 1.2em;
  padding-left: 10px;
}

.mamorio-login-menu {
  max-width: 370px;
  width: 80%;
  height: auto;
  margin: 80px auto 0;
  font-size: 1em;
  line-height: 2em;
  letter-spacing: 0.8px;
  text-align: center;
}

.mamorio-login-menu a {
  color: #4ca7db;
  text-decoration: none;
}

.mamorio-login-menu button.createVerificationAgain {
  cursor: pointer;
  color: #4ca7db;
  border: none;
  background: none;
}

.mamorio-login-copyright {
  bottom: 0;
  margin: 80px 0 20px 0;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  letter-spacing: 0.6px;
  color: #757575;
}

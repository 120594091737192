.detail-modal-container {
  color: #757575;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 40px;
  right: 0;
  z-index: 1000;
  display: flex;
}

.detail-modal-container .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.detail-modal-container .detail-base {
  width: 70%;
  height: 90%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  z-index: 1500;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-radius: 10px;
}

.detail-modal-container .scrollable {
  overflow-y: scroll;
  position: relative;
  padding-bottom: 40px;
  height: 100%;
  border-radius: 10px;
}

.detail-modal-container .title-row {
  border-bottom: solid 1px #dcdcdc;
  display: flex;
  justify-content: space-between;
}

.detail-modal-container .title-row .detail-title {
  padding: 12px 0px 12px 16px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1.1px;
  text-align: left;
  color: #5a5a5a;
  display: inline-block;
}

.detail-modal-container .name-filter {
  padding-left: 16px;
  padding-bottom: 12px;
}

.detail-modal-container .name-filter input {
  margin-left: 12px;
  height: 44px;
}

.detail-modal-container .title-row button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 12px 16px 12px 16px;
  appearance: none;
  vertical-align: baseline;
}

.detail-modal-container .title-row button:hover {
  background-color: #eee;
}

.detail-modal-container .title-row img {
  width: 18.9px;
  height: 18.7px;
}

.detail-modal-container .main-view {
  padding: 20px 36px 0 36px;
  text-align: center;
}

.detail-modal-container .main-view .mamorio-icon {
  width: 74px;
  height: 74px;
  -webkit-border-radius: 74px;
  -moz-border-radius: 74px;
  border-radius: 74px;
}

.detail-modal-container .main-view svg {
  background-color: lightgray;
  padding: 16px;
}

.detail-modal-container .detail-view {
  margin-top: 20px;
}

.detail-modal-container .detail-view .privacy-desc {
  width: 100%;
  height: 64px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
}

.detail-modal-container .detail-view .map-view {
  width: 100%;
}

.detail-modal-container .detail-view .map-view .info-box {
  width: 100%;
  padding: 14px 0 14px 22px;
  background-color: #f8f8f8;
}

.detail-modal-container .detail-view .map-view .map {
  height: 140px;
  background: #f8f8f8;
  margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .detail-modal-container .detail-base {
    width: 100%;
  }
}

.slider-label {
  background: #f8f8f8;
  color: _blank;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 12px;
  width: 140px;
  text-align: center;
}
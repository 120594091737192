.mamorio-spot .spot-item {
    border-bottom: 1px solid #dcdcdc;
    padding: 16px 0px 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mamorio-spot .spot-item .image img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.mamorio-spot .spot-item .name {
    margin-right: auto;
    vertical-align: middle;
    display: inline-block;
    padding-left: 10px;
    color: #5a5a5a;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.mamorio-spot .spot-item .options {
    float: right;
}

.mamorio-spot .empty-list {
    text-align: center;
    margin-top: 40px;
}

.mamorio-spot .empty-list img {
    width: 140px;
}

.mamorio-spot .empty-list .title {
    font-size: 20px;
    color: #5a5a5a;
    line-height: 1.2;
    letter-spacing: 1.3px;
    margin-top: 30px;
}

.mamorio-spot .empty-list .description {
    font-size: 18px;
    color: #5a5a5a;
    line-height: 1.67;
    letter-spacing: 1.1px;
    margin-bottom: 40px;
}

.mamorio-spot .message a {
    color: #4ca7db;
}

/* layout for mobile */
@media (max-width: 768px) { 
    .mamorio-spot .spot-item .options .btn {
        min-width: 70px;
        width: auto;
    }
}

.overview-list-centered {
    justify-content: center;
}

.overview-list .table {
    color: #757575;
    margin-bottom: 20px;
}

.overview-list .table .list {
    background-color: white;
    box-shadow: 0 2px 2px 0 transparent, 0 0 10px 0 rgba(0, 0, 0, .1);
    cursor: pointer;
}

.overview-list .table .header {
    margin-bottom: 5px;
    padding: 0px 15px 0px 15px;
}

.overview-list .table .header th {
    border-top: none;
    padding-bottom: 4px;
}

.overview-list .table .header .title {
    font-size: 12px;
    font-weight: normal;
}

.overview-list .table .header .title img {
    width: 15px;
    height: 15px;
}

.overview-list .table .list .list-row.disabled {
    pointer-events: none;
}

.overview-list .table .list .list-row:not(.disabled):hover {
    background-color: #f9f9f9;
}

.overview-list .table th,
.overview-list .table td {
    border: 0;
}

.overview-list .table .list .divider {
    background-color: white;
    height: 2px;
}

.overview-list .table .list .divider td {
    padding: 0;
}

.overview-list .table .list .divider .line {
    height: 2px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #f4f4f4;
}

.overview-list .table .list .item {
    vertical-align: middle;
    border: 0px;
    height: 70px;
}

.overview-list .img-name-item {
    display: flex;
    align-items: center;
}

.overview-list .img-name-item .item-icon {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    border-radius: 36px;
}

.overview-list .img-name-item svg {
    background-color: lightgray;
    padding: 6px;
}

.overview-list .location-icon,
.overview-list .group-icon {
    height: 20px;
    margin-right: 10px;
}

.overview-list .infobox {
    border-radius: 4px;
    background-color: #f8ecec;
    border: solid 1px #ed7373;
    padding: 16px;
    color: #c9250a;
    margin-bottom: 20px;
}

.overview-list .infobox p {
    margin: 0px;
}


/* ToggleButton Component */

.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    vertical-align: top;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #f0f0f0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #bababa;
}

.react-toggle--checked .react-toggle-track {
    background-color: #4ca7db;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #4ca7db;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    top: 4px;
    bottom: 0px;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #f0f0f0;
    border-radius: 50%;
    background-color: #FFFFFF;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}


/* Mobile Classes */

@media (max-width: 768px) {
    .overview-list {
        margin: 0px;
    }
    .overview-list .table .header th:nth-child(2),
    .overview-list .table .header th:nth-child(3),
    .overview-list .table .header th:nth-child(5),
    .overview-list .table .list td:nth-child(2),
    .overview-list .table .list td:nth-child(3),
    .overview-list .table .list td:nth-child(5) {
        display: none;
    }
    .overview-list .table .list .item {
        font-size: 12px;
    }
    .overview-list .table .list .item:nth-child(1) div {
        line-height: normal;
    }
}

.status-rental {
    height: 20px;
    width: 60px;
    border-radius: 10px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    display: inline-block;
    padding: 0px 10px 0px 10px;
}

.status-rental.rental {
    border: 1px solid #4ca7db;
    color: #4ca7db;
}

.status-rental.returned {
    border: 1px solid #757575;
    color: #757575;
}

.status-detection.detected {
  color: #79AA5A;
}

.status-detection.notdetected {
  color: #757575;
}

.overview-list .table .list .item .mobile-view {
    display: none;
}

@media (max-width: 768px) {
    .overview-list .table .list .item .mobile-view {
        display: block;
        font-size: 12px;
    }
}

.status .sub {
    display: flex;
    margin-top: 5px;
}

.status .sub .rental-owner:not(span) {
    color: #4ca7db;
    font-size: 12px;
    margin-left: 5px;
}

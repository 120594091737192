.success-popup {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFF;
    border-radius: 25px;
    height: 40px;
    border: 1px solid #4ca7db;
    line-height: 40px;
    vertical-align: middle;
    padding: 0px 15px 0px 3px;
    color: #4ca7db;
    font-family: HiraKakuProN-W3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-popup img {
    height: 36px;
    width: auto;
    margin-right: 10px;
}
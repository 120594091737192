.print-seal {
    padding-top: 20px;
}

.print-seal .button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.print-seal .button-section .btn {
  margin-bottom: 10px;
  min-width: 260px;
}

.print-seal .print-seal-content {
    width: 100%;
    padding: 41px 2px 20px 0px;
    display: block;
}

.print-seal .print-seal-content .print-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    text-align: center;
    padding-top: 50px;
}

.print-seal .print-seal-content .print-col {
    flex-basis: 20%;
}

.print-seal .print-seal-content .print-col-title {
    padding-bottom: 2px;
}

.print-seal .print-seal-content .print-col-inside {
    border: 1px solid #919191;
    display: inline-block;
    height: 139px;
    overflow: hidden;
}

.print-seal .print-seal-content .print-col-qr {
    width: 80px;
    transform: scale(1.1, 1.1);
}

.print-seal .print-seal-content .print-col-code {
    font-family: Helvetica;
    font-size: 22px;
    letter-spacing: 3px;
    line-height: 24px;
    padding: 2px 2px 2px 2px;
}

@media print {
    .print-seal footer {
        page-break-before: always;
        padding-top: 40px;
    }

    .print-seal .print-seal-content .print-row:not(:first-child) {
        padding-top: 91px;
    }
}

#group-nav {
    height: 100%;
    overflow: hidden;
}

#content {
    margin-top: 20px;
    padding-right: 25px;
    padding-left: 25px;
}

.overview {
    background-color: #f4f4f4;
    height: 100%;
    padding: 0px 12px 30px 12px;
}

.overview .row {
    flex-wrap: nowrap;
}

.inset-shadow {
    padding-bottom: 50px;
    box-shadow: inset 10px 0px 10px -10px rgba(0, 0, 0, 0.3);
    overflow: auto;
}

.group-nav {
    height: 100%;
    background-color: #2a2a2a;
    font-size: 18px;
    overflow: auto;
    width: 300px;
}

.group-nav .group-nav-header {
    color: #a2a7b2;
    font-weight: normal;
    margin-bottom: 0px;
    letter-spacing: 0.5px;
    padding-left: 20px;
}

.group-nav ul {
    margin-top: 0px;
    padding-left: 0px;
}

.group-nav ul li:hover,
.group-nav ul li:active {
    background-color: #1a1a1a;
}

.group-nav ul li .selected div {
    background-color: #34505F;
}

.group-nav ul li div {
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    padding: 0.3rem 20px;
}

.group-nav ul li div img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.group-nav a,
.group-nav a:hover,
.group-nav a:active {
    color: #FFF;
    text-decoration: none;
    vertical-align: middle;
}

.group-nav hr {
    color: #cccccc;
    opacity: 1;
    margin: 20px;
}

.group-nav .organization {
    text-align: center;
    margin-bottom: 30px;
    padding-top: 25px;
}

.group-nav .organization .no-wrap {
    white-space: nowrap;
}

.group-nav .organization > a > span {
    margin-top: 18px;
    display: block;
    white-space: nowrap;
}

.group-nav .organization-icon {
    width: 75px;
    height: 75px;
    background-color: #ffffff;
    border-radius: 75px;
}

.group-nav .organization a:nth-child(1) {
    display: inline-block;
    width: 79px;
    height: 79px;
    border-radius: 79px;
    border: 2px solid #2a2a2a;
}

.group-nav .organization a:nth-child(1):hover,
.group-nav .organization a:nth-child(1):active {
    border: 2px solid #FFF;
}

.group-nav .organization-settings {
    margin-top: 5px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    white-space: nowrap;
}

.group-nav .organization-settings:hover,
.group-nav .organization-settings:active {
    opacity: 0.7;
}

.group-nav .organization-settings img {
    width: 14px;
    margin-right: 5px;
}

.group-nav .organization-settings span {
    vertical-align: middle;
}


@media only screen and (max-width: 768px) { 
    #group-nav {
       flex: 0 0 300px;
       position: fixed;
       z-index: 1000;
       box-shadow: 10px -10px 10px -10px rgba(0, 0, 0, 0.3);
   }

   #content {
       flex: 0 0 100% !important;
       max-width: 100% !important;
       padding-right: 15px;
       padding-left: 15px;
   }

   .inset-shadow {
       box-shadow: none;
   }
}

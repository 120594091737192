.print-brochure {
    padding-top: 20px;
}

.print-brochure .button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.print-brochure .button-section .btn {
    margin-bottom: 10px;
    min-width: 260px;
}

.print-brochure .print-brochure-content {
    text-align: left;
    width: 600px;
    padding: 20px;
    display: block;
    margin: 0 auto;
}

.print-brochure .print-brochure-content .print-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.print-brochure .print-brochure-content .print-row table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: 20px;
}

.print-brochure .print-brochure-content .print-col {
    border: 1px solid #000;
    padding: 5px;
}

.print-brochure .print-brochure-content .print-col-title {
    font-size: 14px;
    padding-bottom: 2px;
}

.print-brochure .print-brochure-content .print-col-inside {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.print-brochure .print-brochure-content .print-col-desc {
    padding: 0px 0px 5px 5px;
}

.print-brochure .print-brochure-content .print-col-qr {
    width: 100px;
}

.print-brochure .print-brochure-content .print-col-code {
    font-family: Helvetica;
    font-size: 20px;
    padding-top: 5px;
}

@media print {
    .print-brochure footer {
        page-break-before: always;
        padding-top: 40px;
    }
    .print-brochure .print-brochure-content .print-row table {
        margin-top: 50px;
    }
}


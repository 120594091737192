.selectable-list {
  min-width: 420px;
  margin-top: 10px;
  max-height: 280px;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  overflow: scroll;
  color: #5a5a5a;
  display: inline-block;
}
.selectable-list .selectable-item {
  height: 60px;
  padding: 16px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: pointer;
}

.selectable-list .selectable-item:not(:last-child) {
  border-bottom: solid 0.5px #cccccc;
}

.selectable-list .selectable-item:hover {
  background-color: #eee;
}

.selectable-list .selectable-item .selectable-item-content .selectable-item-check-icon {
  width: 28.2px;
  height: 28.2px;
  display: inline-block;
}

.selectable-list .selectable-item .selectable-item-content .selectable-item-label {
  margin-left: 8px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
}

.tab-view {
    margin: 0px 20px 0px 20px;
}

.tab-view .tab-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tab-view .tab-menu .tab {
    flex-grow: 1;
    flex-basis: 25%;
    background-color: #f7f7f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px 0px 10px 0px;
    text-align: center;
    box-shadow: 0px -0.5px 1px 0px rgba(0,0,0,0.15);
}

.tab-view .tab-menu .tab:hover {
    background-color: white;
}

.tab-view .tab-menu .tab a {
    color: #757575;
    font-size: 17px;
}

.tab-view .tab-menu .tab a div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-view .tab-menu .tab a:hover {
    text-decoration: none;
}

.tab-view .tab-menu .tab:not(:last-child) {
    margin-right: 10px;
}

.tab-view .tab-menu .tab-active {
    background-color: white;
}

.tab-view .tab-content {
    position: relative;
    z-index: 200;
    background-color: white;
    box-shadow: 0px 1.5px 1px 0px rgba(0,0,0,0.15);
    padding: 30px;
}

.tab-view .tab-content {
    color: #5a5a5a;
}

.tab-view .tab-content > .title {
    padding: 0px 5px 15px 5px;
    border-bottom: 1px solid #dcdcdc;
    font-size: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.tab-view .tab-content .message {
    margin-top: 30px;
}

.tab-view .page-title {
    font-size: 24px;
    color: #5a5a5a;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
}

.tab-view .page-title img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.tab-view .page-title button {
  margin-left: 10px;
}

/* MOBILE VERSION */

@media only screen and (max-width: 768px) {
    .tab-view {
        margin: 0px;
    }

    .tab-view .tab-content {
        padding: 12px;
    }

    .tab-view .tab-menu .tab a {
      font-size: 13px;
    }
}
.group-manager .message a {
    color: #4ca7db;
}

/* for empty screen */
.group-manager .empty-list {
    text-align: center;
    margin-top: 40px;
    color: #5a5a5a;
}

.group-manager .empty-list img {
    width: 140px;
}

.group-manager .empty-list .title {
    font-size: 20px;
    margin-top: 30px;
}

.group-manager .empty-list .description {
    font-size: 18px;
    margin-bottom: 40px;
}

.group-manager .group-lists-box {
    margin: 30px 0;
}

.group-manager .group-lists-box .group-settings-column {
    width: auto;
    padding: 20px 0px 20px;
}

.group-manager .group-lists-box .group-settings-column .group-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group-manager .group-lists-box .group-settings-column img.group-settings-image {
    width: 40px;
    margin: 0 10px 0 0;
    display: inline-block;
    vertical-align: middle;
}

.group-manager .group-lists-box .group-settings-column .group-name {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    font-size: 18px;
    text-align: left;
    color: #5a5a5a;
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.group-manager .group-settings-column .group-update {
    float: right;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
}

.group-manager .group-settings-column .group-update.desktop button {
  margin-left: 10px;
}

.group-manager .group-settings-column .mobile {
    display: none;
}

.group-manager .group-lists-box .group-user-column {
    width: auto;
    padding: 20px 0px 20px;
}

.group-manager .group-user-column .group-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group-manager .group-user-column .group-user-cell,
.group-manager .group-user-column .group-user-cell-expand  {
    margin: 16px 0px 16px 0px;
}

.group-manager .group-user-column .group-user-cell .remove-from-group.mobile {
    display: none;
}

.group-manager .group-user-cell .group-user-settins-image,
.group-manager .group-user-cell-expand .group-user-settins-image  {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin: 0 10px 0 0;
}

.group-manager .group-user-cell .user-mail,
.group-manager .group-user-cell-expand .user-mail {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    text-align: left;
    color: #5a5a5a;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: auto;
}

.group-manager .group-user-cell .admin-label {
  margin-right: 20px;
    /* background: #dc3545; */
    padding: 5px;
    border-radius: 5px;
    /* color: white; */
    font-size: 12px;
}

.group-manager .group-user-cell .remove-from-group {
    margin-left: auto;
}

.group-manager .group-user-cell .remove-from-group,
.group-manager .group-user-cell-expand .remove-from-group  {
    float: right;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    padding: 3px 0px;
}

.group-manager .group-user-cell,
.group-manager .group-user-cell-expand {
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
    cursor: pointer;
}

.group-manager .group-user-cell .group-user-invitation img,
.group-manager .group-user-cell-expand .group-user-invitation img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
}

.group-manager .group-user-cell .group-user-invitation div,
.group-manager .group-user-cell-expand .group-user-invitation div {
    font-size: 18px;
    color: #4ca7db;
    vertical-align: middle;
    display: inline-block;
    padding-left: 20px;
}

.group-manager .group-user-cell-expand .group-user-cell-expand-add-button {
    margin-top: 8px;
}

.group-manager .group-user-cell-expand .member-add-for-group input {
    background: none;
    border: none;
    width: 100%;
    padding: 30px 0px 10px 0px;
    outline: none;
    color: #212529;
    border-bottom: 1px solid #dcdcdc;
}

.group-manager .group-user-cell-expand .member-add-for-group input::placeholder {
    color: #a0a0a0;
}

@media (max-width: 768px) { 
    .group-manager .group-settings-column .group-update.desktop,
    .group-manager .group-settings-column .desktop {
        display: none;
    }
    .group-manager .group-settings-column .mobile {
        display: block;
        width: 90px;
    }

    .group-manager .group-settings-column .group-update.mobile > button {
        width: 90px;
        margin-top: 5px;
    }

    .group-manager .group-user-column .group-user-cell .remove-from-group.mobile {
        display: block;
    }

    .group-manager .group-user-cell .remove-from-group.desktop {
        display: none;
    }

    .group-manager .group-user-column .group-user-cell .remove-from-group.mobile > button {
        min-width: 70px;
        width: auto;
    }

    .group-manager .empty-list .description {
        font-size: 12px;
    }
}

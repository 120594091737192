/* layout for mobile */
@media (max-width: 768px) { 
    .create-group-modal .group-menber-culum {
        margin: 30px 0px 0px 0px;
    }

    .create-group-modal .group-menber-culum .group-text-label {
        margin-bottom: 10px;
    }
}

.unauthorized {
    position: relative;
    height: 100%;
    width: 100%;
}

.unauthorized .notice {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.unauthorized .notice img {
    height: 200px;
    margin-bottom: 16px;
}

.unauthorized .notice h1 {
    font-size: 32px;
    color: #4ca7db;
}

.unauthorized .notice h2 {
    font-size: 24px;
}
.user-settings {
    margin: 0px 20px 0px 20px;
}

.user-settings .user-settings-title {
    width: 100%;
    padding-bottom: 30px;
    color: #5a5a5a;
    letter-spacing: 1.5px;
    font-size: 24px;
}

.user-settings .user-settings-title img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.user-settings .user-settings-title .logout {
    font-size: 16px;
    color: #4ca7db;
    cursor: pointer;
    margin-top: 10px;
}

.user-settings .user-settings-box {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    text-align: center;
}

.user-settings .user-settings-box .user-settings-inner {
    display: inline-block;
}

.user-settings .user-settings-box input[type="file"] {
    display: none;
}


.user-settings .user-settings-box .user-settings-image {
    width: 34px;
    height: 34px;
    margin: 0 5px 0 0;
    display: inline-block;
}

.user-settings .user-settings-box .user-settings-column {
    padding: 15px 30px 15px;
    color: #5a5a5a;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-settings .user-settings-box .user-settings-column label {
    margin: 0px 20px 0px 0px;
}

.user-settings .user-settings-box .user-settings-thumbnail-column {
    padding: 30px;
    text-align: center;
}

.user-settings .user-settings-box .user-settings-button-column {
    padding: 30px;
    text-align: center;
}

.user-settings .user-settings-box .user-settings-button-column .disabled {
    background-color: #e8e8e8 !important;
    color: #9d9d9d !important;
}

.user-settings .user-settings-box .user-settings-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin: 0 auto 15px;
    background-color: #E8E8E8;
    background-image: url('../../icons/icon_circle@2x.png');
    background-size: 100px;
}

.user-settings .user-settings-box .user-settings-thumbnail-change {
    width: auto;
    height: auto;
    padding: 6px 0 0;
    display: inline-block;
    vertical-align: top;
    letter-spacing: 0.8px;
    display: inline-block;
    cursor: pointer;
}

.user-settings .user-settings-box .user-settings-thumbnail-change label {
    color: #4ca7db;
    text-decoration: none;
    cursor: pointer;
}

.user-settings .user-settings-box input {
    width: 100%;
    max-width: 400px;
    height: 44px;
    border: 1px solid #dcdcdc;;
    border-radius: 4px;
    padding: 0 0 0 2%;
    color: #5a5a5a;
}

.user-settings .user-settings-box input:disabled {
    background-color: #eee;
}

.user-settings .user-settings-box input::placeholder {
    color: #9a9a9a;
}

.user-settings .user-settings-box .user-settings-button-column .btn {
    width: 200px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "中ゴシックBBB", "Gothic Medium BBB", "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
  height: 100%;
  background-color: #f4f4f4;
}

html {
  font-size: 16px;
  height: 100%;
}

#root {
  height: 100%;
}

.content {
  height: 100%;
  width: 100%;
  position: fixed;
}

.login-content {
  height: 100%;
  width: 100%;
}

.row-no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form-control::placeholder {
  color: #999 !important;
}

.btn {
    border: none;
    width: auto;
}

.btn:active {
    box-shadow: 0px 1px 3px 3px rgba(0,0,0,0.1);
}

.btn:disabled {
    box-shadow: none;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-lg {
    font-size: 1.2rem;
    letter-spacing: 0.8px;
    width: 100%;
}

.btn-sm {
    font-size: 0.75rem;
    height: 2.06rem;
    width: 130px;
}

.btn-primary {
    background-color: #4ca7db;
    color: white;
}

.btn-primary:disabled {
    background-color: #e8e8e8;
    color: #9d9d9d;
}

.btn-primary:not(:disabled):not(.disabled):hover {
    background-color: #369cd6;
}

.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #369cd6;
    color: white;
}

.btn-outline-primary {
    border: solid 1px #4ca7db;
    color: #4ca7db;
}

.btn-outline-primary:hover {
    background-color: white;
    border: solid 1px #4ca7db;
    color: #4ca7db;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: white;
    border: solid 1px #4ca7db;
    color: #4ca7db;
}

.loading-bg {
    position: fixed;
    z-index: 9000;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.loading-bg .loading-circle {
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 180px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-bg .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    transform: translate(-50%, -50%);
    padding-top: 50px;
    line-height: 30px;
    background-color: #FFF;
    text-align: center;
    color: #000000;
    z-index: 9000;
}

.loading-bg .loading img {
    width: 20px;
}

@keyframes jump {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    20% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.loading-bg .jump {
    -webkit-animation: jump 1.5s ease 0s infinite normal;
    animation: jump 1.5s ease 0s infinite normal;
}
